import React from "react"
import ZppPage from "src/components/zpp-page.jsx"

export default function AOSA(props) {
  return ZppPage({
    registrationCode: process.env.GATSBY_ZPP_REGISTRATION_CODE,
    priceId: process.env.GATSBY_AOSA_PRICE_ID,
    hideInProduction: false,
    title:
      "Nutze den App-basierten Onlinekurs Stressmanagement durch Achtsamkeit (AOSA) über deine Krankenkasse!",
    shortCourseName: "AOSA",
    longCourseName:
      "App-basierter Onlinekurs Stressmanagement durch Achtsamkeit",
    topImage: "/assets/img/aosa/top_background.jpg",
    introduction: (
      <>
        Der Kurs "App-basierter Onlinekurs Stressmanagement durch Achtsamkeit"
        ist als Präventionsmaßnahme von der Zentralen Prüfstelle für Prävention
        (ZPP) zertifiziert und hilft Dir, einen gesunden Umgang mit Stress zu
        finden.
        <br />
        <br />
        Du wirst lernen was Stress eigentlich ist und warum Achtsamkeit dabei
        helfen kann mit Stress konstruktiver umzugehen. Du lernst verschiede
        formale Meditationen kennen, um dich zu entspannen und dir eine Auszeit
        zu gönnen. Nützliche Alltagsübungen werden Dir helfen eine achtsame
        Haltung im Alltag zu integrieren, stressverstärkende Gedanken und andere
        Stressoren zu erkennen und zu reduzieren. Außerdem soll es darum gehen
        Deine Ressourcen zu identifizieren und weitere Strategien zur
        Stressbewältigung zu erlenen.
        <br />
        <br />
        <b>Bitte beachte, dass Dein Mindestalter 18 Jahre betragen muss.</b>
      </>
    ),
    howIsTheCourseStructured: (
      <>
        Der Kurs besteht aus 8 Modulen und dauert 8 Wochen. Jedes Modul dauert
        60 Minuten und besteht aus zwei Teilen - einem Informations- und einem
        praktischen Übungsteil. Der praktische Teil besteht aus einer
        Achtsamkeitsübung, einer weiteren Stressbewältigungstechnik und einem
        Quizz. Außerdem erhältst du über eine E-Mail eine Zusammenfassung. Neben
        dem Pflichtmodul hast Du zwischen den wöchentlichen Modulen jeden Tag
        Zugang zu einer freiwilligen audiogeführten Meditation über die Mindance
        App, die Du jedoch nicht durchführen musst, um Deine
        (Teil-)Rückerstattung zu erhalten. Auch die anderen Funktionen in der
        Mindance App (In-App Umfrage, Trainingsplan, Timer & Gong, Kurse &
        Übungen) sind ebenfalls optional. Die benannten Funktionen gehören also
        nicht zum zertifizierten Präventionskurs und müssen nicht durchgeführt
        werden, um die (Teil-)Rückerstattung zu erhalten.
      </>
    ),
    image1: "/assets/img/aosa/1.png",
    image2: "/assets/img/aosa/2.png",
  })
}
